.background {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  object-fit: contain;
  pointer-events: none;

  &__right-glow {
    position: absolute;
    left: auto;
    top: -8%;
    right: -12%;
    bottom: 0;
    z-index: 1;
    width: 70%;
    height: 70%;
  }

  &__left-glow {
    position: absolute;
    left: -9%;
    top: -7%;
    right: auto;
    bottom: 0;
    width: 70%;
    height: 70%;
  }
}

.loop-app {
  overflow: visible;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  margin: 0 56px 0 56px;
  position: relative;

  @media screen and (max-width: 780px) {
    margin: 0 20px 0 20px;
  }

  &__content {
    margin-top: 16px;

    @media screen and (max-width: 1080px) {
      margin-top: 20px;
      margin-left: 0px;
    }
  }
}