.steps {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: calc(3 * 42px + 2 * 40px);
  margin-left: auto;
  margin-right: auto;

  &__step-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__step-circle {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ced6dc;
  }

  &__step-label {
    margin-top: 16px;
    font-weight: 500;
    font-size: 16px;
    min-height: 26px;
    min-width: 52px;
  }

  &__step-circle-dark {
    background-color: #0f3149;
  }

  @media screen and (max-width: 780px) {
    width: calc(3 * 32px + 2 * 30px);
    margin-left: 0;
    margin-right: 0;

    &__step-circle {
      width: 14px;
      height: 14px;
    }

    &__step-label {
      margin-top: 12px;
      font-size: 14px;
      min-height: 22px;
      min-width: 42px;
    }
  }
}
