.landing {

  &__headline h1 {
    font-size: 52px;
    line-height: 1.2;
    font-weight: 500;
    color: var(--main-color);
    letter-spacing: normal;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
  }

  &__content-left {
    width: 45%;
    margin-top: 40px;
  }

  &__content-right {
    width: 420px;
  }

  &__text {
    margin-top: 6px;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    color: var(--main-color);
    width: 90%;
  }

  &__button-container {
    position: relative;
    margin-top: 50px;
  }

  &__image {
    width: auto;
    height: auto;
    max-width: 100%;
  }

  @media screen and (max-width: 1080px) {
    &__content-left {
      width: 90%;
      margin-top: 16px;
    }

    &__content-right {
      margin-top: 40px;
      width: 54%;
    }

    &__image {
      max-width: 90%;
    }
  }

  @media screen and (max-width: 780px) {
    &__headline h1 {
        font-size: 36px;
        line-height: 1.2;
        width: 96%;
    }
    &__text {
        margin-top: 0px;
        font-size: 20px;
        width: 100%;
    }
    &__button-container {
        left: 0px;
        margin-top: 40px;
    }
    &__content-right {
        width: 64%;
    }
  }

  @media screen and (max-width: 580px) {
    &__content-right {
      width: 84%;
    }
  }
}