.setup {
  &__headline h1 {
    font-size: 52px;
    line-height: 1.2;
    font-weight: 500;
    color: var(--main-color);
    letter-spacing: normal;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 44px;
  }

  &__text {
    margin-top: 6px;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    color: var(--main-color);
    width: 90%;
  }

  &__button-container {
    position: relative;
    width: 100%;
    margin-top: 50px;
  }

  &__checklist {
    list-style: none;
    list-style-image: none;
    width: 100%;
    padding-left: 0;
    padding-bottom: 30px;
  }

  &__checklist-item {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      padding-bottom: 24px;
    }
  }

  &__checklist-item-name {
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    margin-left: 6px;
  }

  &__auxiliary-static-check {
    display: inline-flex;
    align-items: center;
    height: 42px;

    &:not(:last-of-type) {
      padding-bottom: 24px;
    }

    & svg {
      min-width: 23px;
    }
  }

  &__description {
    font-size: 22px;
    line-height: 1.2;
    font-weight: 700;
    color: var(--main-color);
    padding-bottom: 8px;
  }

  &__auxiliary {
    list-style: none;
    list-style-image: none;
    width: 100%;
    padding-left: 0;
  }

  &__multiline-description {
    font-size: 16px;
    line-height: 2.4;
    font-weight: 500;
    color: var(--main-color);
    padding-top: 10px;
    padding-bottom: 8px;
  }

  &__single-line-description {
    font-size: 16px;
    line-height: 2.4;
    font-weight: 500;
    color: var(--main-color);
    padding-top: 16px;
    padding-bottom: 15px;
  }

  &__auxiliary-static-check-name {
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    margin-left: 8px;
  }

  &__auxiliary-item-name {
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    font-family: "Satoshi", sans-serif;
    letter-spacing: normal;
  }

  &__auxiliary-item-selection {
    margin-top: 8px;
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;

    .MuiFormControlLabel-label.Mui-disabled {
      color: rgba(15, 49, 73, 1);
    }
  }

  &__auxiliary-input {
    margin-left: 31px !important;

    &.MuiFormControl-fullWidth {
      @media screen and (max-width: 780px) {
        max-width: 90%;
      }

      @media screen and (max-width: 580px) {
        max-width: 90%;
      }
    }

    .MuiOutlinedInput {
      &-root {
        font-size: 18px;
        background-color: #fff;
        width: 460px;
        margin-top: 10px;

        @media screen and (max-width: 780px) {
          width: 440px;
        }

        @media screen and (max-width: 580px) {
          width: 300px;
        }

        &.Mui-focused fieldset {
          border-color: var(--main-color);
        }
      }

      &-input {
        padding: 14px 16px;
        font-family: "Satoshi", sans-serif;
      }
    }

    .MuiFormHelperText-contained {
      margin-left: 0px !important;
      font-size: 12px;
      font-weight: bold;
      font-family: "Satoshi-Variable", sans-serif;
    }

    .Mui-error {
      color: #bf3d3d !important;

      .MuiOutlinedInput-notchedOutline {
        border-color: #bf3d3d !important;
        border-width: 1.5px !important;
      }
    }
  }

  &__auxiliary-item--selected {
    color: #f37e01;
  }

  &__auxiliary-item--unselected {
    color: #0f3149;
  }


  @media screen and (max-width: 1080px) {
    &__content {
      width: 100%;
      margin-top: 40px;
    }
  }

  @media screen and (max-width: 780px) {
    &__headline h1 {
      font-size: 36px;
      line-height: 1.2;
      width: 86%;
    }

    &__text {
      margin-top: 0px;
      font-size: 20px;
      width: 90%;
    }

    &__button-container {
      left: 0px;
      margin-top: 40px;
    }

    &__checklist {
      padding-bottom: 24px;
    }

    &__description {
      padding-bottom: 0px;
    }

    &__single-line-description {
      line-height: 1.4;
      padding-top: 20px;
      padding-bottom: 24px;
    }

    &__multiline-description {
      line-height: 1.4;
      padding-top: 20px;
      padding-bottom: 0px;
    }
  }

  @media screen and (max-width: 580px) {
    &__auxiliary-item-selection {
      padding-top: 13px;
    }
  }
}