.logo {
  grid-area: 1 / 1 / 2 / 2;

  &__image {
    width: 96px;
    
    @media screen and (max-width: 780px) {
      width: 98px;
    }
  }
}
