.meeting-coach__results {
  .MuiCircularProgress-colorPrimary {
    color: #f28242;
  }

  &-top {
    padding: 80px 40px !important;
    padding-right: 15% !important;
    background: #f28242;
    font-weight: 400;
    font-size: 50px;
    line-height: 58px;
    color: #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 70px !important;
  }

  &-bottom {
    background-color: #f4f4f4;
    padding-top: 70px !important;

    &-img {
      padding-bottom: 0px !important;

      img {
        width: 100%;
      }
    }

    &-title {
      font-size: 35px;
      line-height: 42px;
      font-weight: 400;
      padding-left: 2rem !important;
    }

    &-description {
      padding-top: 30px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
      padding-left: 2rem !important;

      & a {
        color: #f28242;
        font-weight: 400;
      }
    }

    &-share {
      margin-top: 4rem;
      padding-left: 2rem;
    }

    &-media {
      margin-top: 1rem;
      margin-bottom: 2rem;
      padding-left: 2rem;
      width: 100%;

      &-copy {
        padding: 10px 30px !important;
      }
    }
  }
}

.storyboard {
  &__heading {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 1rem;
  }

  &__title {
    margin-top: 1rem;
    font-size: 20px;
    line-height: 28px;
  }

  &__description {
    font-size: 20px;
    line-height: 28px;
  }

  &__button-container {
    position: relative;
    margin-top: 50px;
  }

  @media screen and (max-width: 780px) {
    &__button-container {
      left: 0px;
      margin-top: 40px;
    }
  }
}

@media (max-width: 960px) {
  .meeting-coach__results {
    &-top {
      padding: 40px 30px !important;
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 60px !important;
    }

    &-bottom {
      &-title {
        font-size: 25px;
        line-height: 33px;
        padding: 10px 20px !important;
      }

      &-description {
        padding: 10px 20px !important;
      }

      &-share {
        margin-top: 2rem;
        padding: 10px 20px;

        & button {
          right: 10px;
        }
      }
    }
  }
}

.context {
  padding-left: 2rem !important;
  padding-right: 15% !important;

  &__title {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 1rem;
  }

  &__description {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 960px) {
  .context {
    padding: 10px 20px !important;
  }
}

.graph-context {
  padding-left: 0.45rem;

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: #f28242;
  }

  &__description {
    padding-left: 0.1rem;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }
}

@media (max-width: 960px) {
  .graph-context {
    padding: 0 !important;
  }
}

.graph-section {
  margin-top: 30px !important;
  padding-left: 2rem !important;
  padding-right: 15% !important;
  margin-bottom: 60px !important;
}

@media (max-width: 960px) {
  .graph-section {
    padding: 0 25px !important;
    margin-bottom: 40px !important;

    & svg {
      width: 100% !important;
    }
  }
}