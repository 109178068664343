.pricing {
  @media (max-width: 960px) {
    &__second-column {
      margin-top: 0 !important;
      padding-top: 0 !important;
      p {
        margin-top: 0;
      }
    }
  }
}
