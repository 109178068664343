.zoom-meetings-viewer {
  &__title {
    margin-top: 1rem;
  }

  &__meetings {
    margin-bottom: 6rem;
  }

  &__load-transcript {
    position: fixed !important;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($color: #eae2b7, $alpha: 0.75);
    padding: 1rem;
  }

  &__skip {
    margin-top: 0.5rem;

    a {
      background-color: #eae2b7;
      padding: 0 0.5rem;
    }
  }

  &__button-container {
    margin-top: 50px;
  }

  @media screen and (max-width: 780px) {
    left: 0px;
    margin-top: 40px;
  }

  @media (max-width: 599px) {
    &__skip {
      text-align: center;
    }
  }
}

.meetings-day {
  margin-top: 1rem;

  .MuiRadio-colorSecondary.Mui-checked {
    color: #f37e02 !important;
  }
}
