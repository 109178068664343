:root {
  --main-color: #0f3149;
  --grey-color: #CED6DC;
  --text-light: #EAE2B7;
  --text-orange: #f37e02;
}

* {
  box-sizing: inherit;
}

html {
  height:100%;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

body {
  height:100%;
  background-color: #fffefc;
  font-family: "Satoshi", sans-serif;
  color: var(--main-color);
  font-size: 1rem;
  line-height: 1.5;
  border: 0;
  margin: 0;
}

.MuiTypography {
  &-h1,
  &-h2,
  &-h3,
  &-h4 {
    font-family: "Satoshi", sans-serif !important;
  }
}

#root {
  height: 100%;
}
