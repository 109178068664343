.button.MuiButton-root {
  padding: 0 60px;
  height: 46px;
  background: var(--main-color);
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--text-light);
  transition: none;
  text-transform: none;

  & .MuiButton-label {
    font-family: 'Satoshi', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: normal;
  }

  &:disabled {
    border-color: #aaa;
    background-color: #aaa;
    color: #ddd;
  }

  &:hover {
    background: linear-gradient(180deg, #e48935, #eeae0a);
    color: #0F3149;
  }

  @media screen and (max-width: 780px) {
    height: 48px;
    border-radius: 70px;

    & .MuiButton-label {
      font-size: 18px;
      line-height: 30px;
    }
  }

  @media screen and (max-width: 580px) {
    padding: 0 30px;
    min-width: 160px;
  }
}