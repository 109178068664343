.user-manager {
  &__invite-email {
    font-size: 1rem !important;
    padding: 5px 140px 4px 20px !important;
    background-color: #fff !important;
    border-radius: 20px !important;
  }

  &__invite-btn {
    font-size: 1.05rem !important;
    text-transform: none !important;
    position: relative !important;
    float: right !important;
    top: -2.6rem !important;
    border-radius: 20px !important;
    background-color: #0f3149 !important;
    color: #eae2b7 !important;

    &:disabled {
      background-color: #aaa !important;
      color: #ddd !important;
    }
  }

  &__invited {
    &-email {
      display: inline-block;
      padding-top: 5px;
    }
    &:hover {
      background-color: #fdf6c8 !important;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #0f3149;
    }
  }

  &__uninvite-btn {
    float: right;
    color: #0f3149 !important;
    background-color: #fff !important;

    &:hover {
      color: #eae2b7 !important;
      background-color: #0f3149 !important;
    }
  }

  &__upgrade-link {
    text-decoration: underline;
    text-underline-offset: 5.4px;
    text-decoration-thickness: .07em;
  }
}
