.top-banner {
  width: 100%;
  height: 120px;
  display: grid;
  margin-top: 24px;
  grid-template-columns: .46fr 1fr .46fr;
  grid-template-rows: 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 0px;
  color: var(--main-color);
  position: relative;

  @media screen and (max-width: 980px) {
    height: 100px;
    grid-template-columns: .46fr 1fr;
  }

  @media screen and (max-width: 780px) {
    height: 100px;
    grid-template-columns: .56fr 1fr;
  }
}