.setup__alert {
  font-family: "Satoshi-Variable";
  font-weight: 500;
  font-size: 18px;
  counter-reset: item;
  margin-left: 0;
  padding-left: 0;

  &__preamble {
    margin-top: 30px;
    margin-bottom: -15px;
  }

  & ul {
    padding-top: 1em;
    padding-inline-start: 40px;
  }

  &__item {
    display: block;
    margin-bottom: 0.25em;

    &::before {
      display: inline-block;
      content: "(" counter(item) ") ";
      counter-increment: item;
      width: 25px;
      margin-left: -2em;
    }
  }
}
