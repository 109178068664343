.nav {
  &__menu-item {
    box-sizing: border-box;
    color: var(--main-color);
    display: block;
    float: left;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }

  &__menu-items {
    list-style: none;
    padding-top: 1em;
    padding-bottom: 1em;
    right: 0px;
    position: absolute;
    z-index: 22;
  }

  &__menu-item:hover {
    color: var(--text-orange);
  }

  &__menuspacer {
    width: .5px;
    padding: 0px;
    margin: 0px 10px;
    height: 30px;
    position: relative;
    top: -3px;
    background-color: var(--main-color);
  }

  &__mobile-menu {
    display: none;
    right: 62px;

    @media screen and (max-width: 980px) {
      display: block;
      padding: 10px;
      text-align: right;
      right: 0px;
      position: absolute;
    }
  }

  &__mobile-menu-button {
    position: relative;
    z-index: 22;
    fill: var(--main-color);

    &:hover {
      fill: var(--text-orange);
    }
  }

  &__mobile-menu-background {
    background-color: #ffffffee;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    position: fixed;
    z-index: 21;
  }

  &__mobile-menu-background--hide {
    display: none;
  }

  &__mobile-menu-background--show {
    display: block;
  }

  @media screen and (max-width: 980px) {
    &__menu-item {
      width: 100%;
      text-align: right;
      padding-right: 6px;
      height: 50px;
    }

    &__menu-items {
      display: none;

      &--show {
        display: block;
        z-index: 100;
        right: 6px;
        top: 54px;
        width: 180px;
        line-height: 2;
        position: absolute;
        text-align: right;
      }
    }

    &__menuspacer {
      display: none;
    }
    
  }
}