.alert-dialog {
  padding: 42px 32px;
  background-color: #0f3149;
  color: #eae2b7;

  &__actions button {
    margin-top: 45px;
    padding: 12px 40px;
  }
}
